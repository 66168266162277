<template>
  <v-app>
    <div class="card card-custom card-stretch">
      <div class="card-body table-responsive m-5">
        <!--   Begin Application Discussion   -->
        <div class="d-flex align-items-start list-item card-spacer-x py-3">
          <div class="d-flex align-items-center">
            <div
              class="d-flex align-items-center flex-wrap w-xxl-350px"
              data-toggle="view"
            >
              <div class="symbol symbol-35px me-3">
                <div class="symbol-label bg-light-danger">
                  <span class="text-danger">{{ discussion.symbol_label }}</span>
                </div>
              </div>
              <div class="ml-5">
                <span
                  class="
                    text-primary
                    font-weight-bolder font-size-lg
                    text-hover-primary
                  "
                >
                  {{ discussion.name }}
                  <br />
                 Category: {{ discussion.category_name }}
                </span>
              </div>
            </div>
            <div
              class="d-flex align-items-center flex-wrap w-xxl-250px"
              data-toggle="view"
            >
              <div class="ml-5">

                  <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">User: </span> {{ discussion.user ? discussion.user : 'N/A' }} <br>
                <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">Agent: </span> {{ discussion.agent ? discussion.agent : 'N/A'}}
              </div>
            </div>
            <div
              class="d-flex align-items-center flex-wrap w-xxl-350px"
              data-toggle="view"
            >
              <div class="ml-5">

                  <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">Institution: </span> {{ discussion.application_institution ? discussion.application_institution : 'N/A' }} <br>
                <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">Academic Program: </span> {{ discussion.application_academic_program ? discussion.application_academic_program : 'N/A' }}
              </div>
            </div>
            <div
              class="d-flex align-items-center flex-wrap w-xxl-300px"
              data-toggle="view"
            >
              <div class="ml-5">
                Created At:
                <span class="flex-grow-1 ml-5 mt-4 mr-2 text-muted">
                  {{ discussion.formatted_created_at }}
                </span>
                <br>
                Replied At:
                <span class="flex-grow-1 ml-5 mt-4 mr-2 text-muted">
                  {{ discussion.formatted_updated_at }}
                </span>
              </div>
            </div>
            <div
                class="d-flex align-items-center flex-wrap w-xxl-200px"
                data-toggle="view"
            >
              <div class="ml-5">
              <span class="flex-grow-1 text-muted">
                  {{ discussion.readable_created_at }}
                </span>
                <br>
                <span class="badge badge-primary" v-if="discussion.discussion_status != 'Closed'">
                  {{ discussion.discussion_status }}
                </span>
                <span class="badge badge-danger" v-else>
                  {{ discussion.discussion_status }}
                </span>
              </div>
            </div>
            <div
                class="d-flex align-items-center flex-wrap w-xxl-100px"
                v-if="discussion.is_closed"
            >
              <v-btn
                  class="ml-3 text-white"
                  @click="back"
                  color="red"
              >Back</v-btn
              >
            </div>
            <div
                class="d-flex align-items-center flex-wrap w-xxl-100px"
                v-if="!discussion.is_closed"
            >
              <v-btn
                  class="text-right"
                  icon
                  rounded
                  x-large
                  color="red"
                  @click="closeTicket"
              ><v-icon>mdi-close-circle</v-icon></v-btn>
            </div>
          </div>

        </div>
        <v-row
          class="ml-2 mb-2"
          v-if="discussion && discussion.attachments != null"
        >
          <v-col
            v-for="(attachment, index) in discussion.attachments"
            :key="index"
            class="d-flex child-flex"
            cols="1"
          >
            <a
                v-if="attachment.file_path && attachment.file_type == 'image'"
                :href="attachment.file_path.real"
                target="_blank"
            >
              <v-img
                  :lazy-src="attachment.file_path.real"
                  :src="attachment.file_path.real"
                  aspect-ratio="1"
                  max-width="100"
                  max-height="100"
                  class="grey lighten-2"
              >
              </v-img>
            </a>
            <a
                v-if="attachment.file_path && attachment.file_type == 'pdf'"
                :href="attachment.file_path.real"
                target="_blank"
            >
              <v-img
                  :lazy-src="require('@/assets/media/pdf.png')"
                  :src="require('@/assets/media/pdf.png')"
                  aspect-ratio="1"
              >
              </v-img>
            </a>
            <a
                v-if="attachment.file_path && attachment.file_type == 'doc'"
                :href="attachment.file_path.real"
                target="_blank"
            >
              <v-img
                  :lazy-src="require('@/assets/media/document.png')"
                  :src="require('@/assets/media/document.png')"
                  aspect-ratio="1"
                  max-width="100"
                  max-height="100"
                  class="grey lighten-2"
              >
              </v-img>
            </a>
            <a
              v-if="attachment.attachment_url"
              :href="attachment.attachment_url"
              target="_blank"
            >
              Attachment Url {{ index + 1 }}
            </a>
          </v-col>
        </v-row>
        <v-row
          class="ml-2 mb-2"
        >
          <v-col
            cols="12"
          >
            <span class="font-weight-bold">Description: </span> <p v-html="discussion.description"></p>
          </v-col>
        </v-row>
        <!--   End Application Discussion   -->
        <v-divider></v-divider>

        <!--   Begin Application Discussion Threads   -->
        <div
          class="list list-hover min-w-500px"
          v-for="(thread, index) in discussion_threads"
          :key="index"
        >
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div
                  class="d-flex align-items-start list-item card-spacer-x py-3"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center flex-wrap w-xxl-50px">
                      <div class="symbol symbol-35px me-3">
                        <div class="symbol-label bg-light-danger">
                          <span class="text-danger">{{
                            thread.admin_user_symbol_label
                              ? thread.admin_user_symbol_label
                              : thread.user_symbol_label ? thread.user_symbol_label : thread.agent_symbol_label
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        align-items-center
                        flex-wrap
                        w-xxl-600px
                        ml-5
                      "
                    >
                      <span class="font-size-sm text-black">
                        {{
                          thread.admin_user
                            ? thread.admin_user
                            : thread.user
                            ? thread.user
                            : thread.agent
                        }}
                      </span>
                      <br>
                      <span class="ml-3 badge badge-info">
                          {{thread.replied_by_text ? thread.replied_by_text : null}}
                       </span>
                      <span class="float-left text-muted">
                        <i class="text-success mx-5 fas fa-circle fa-xs"></i>
                        {{ thread.readable_created_at }}
                      </span>
                    </div>
                  </div>
                  <div class="flex-grow-1 mt-5">
                    <span class="float-right text-muted">
                      {{ thread.formatted_created_at }}
                    </span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="thread.description" class="m-5"></div>
                <div class="m-5" v-if="thread.discussion_attachments.length > 0">
                  <span
                    v-for="(attachment, index) in thread.discussion_attachments"
                    :key="index"
                  >
                    <a
                      v-if="attachment.file_path"
                      :href="attachment.file_path.real"
                      class="
                        text-primary
                        font-weight-bolder
                        mb-1
                        mx-2
                        font-size-lg
                      "
                      target="_blank"
                    >
                      Attachment {{ index + 1 }}
                    </a>
                    <a
                      v-if="attachment.attachment_url"
                      :href="attachment.attachment_url"
                      class="
                        text-primary
                        font-weight-bolder
                        mb-1
                        mx-2
                        font-size-lg
                      "
                      target="_blank"
                    >
                      Attachment URL {{ index + 1 }}
                    </a>
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider></v-divider>
        </div>
        <!--   End Application Discussion Threads   -->
        <div class="m-5" v-if="!discussion.is_closed">
          <v-row>
            <v-col cols="12">
              <ckeditor
                :config="editorConfig"
                v-model="discussion_thread.description"
              >
              </ckeditor>
              <span
                class="text-danger"
                v-if="$v.discussion_thread.description.$error"
                >This Field is required</span
              >
            </v-col>
            <v-col cols="12" md="4">
              <div class="text-left">
                <v-select
                  label="Attachment Type"
                  v-model="discussion_thread.attachmentType"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                >
                </v-select>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <div class="text-left">
                <v-file-input
                    v-if="discussion_thread.attachmentType == 'file'"
                    label="Attachment Files"
                    v-model="discussion_thread.files"
                    :error="$v.discussion_thread.files.$error"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    @change="previewImage"
                    multiple
                    clearable
                    counter
                    :show-size="1000"
                    outlined
                    dense
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 3"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 3"
                        class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ discussion_thread.files.length - 3 }} File(s)
                  </span>
                  </template>
                </v-file-input>
                <span class="text-danger" v-if="$v.discussion_thread.files.$error">This Field is required</span>
                <v-text-field
                    v-if="discussion_thread.attachmentType == 'url'"
                    label="Attachment Url"
                    v-model="discussion_thread.attachment_url"
                    :error="$v.discussion_thread.attachment_url.$error"
                    outlined
                    dense
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.discussion_thread.attachment_url.$error">This Field is required</span>
              </div>
            </v-col>
            <v-col cols="12" md="12" v-if="images.length > 0">
              <v-row>
                <v-col cols="12" md="3" v-for="(image, index) in images" :key="index">
                  <v-img
                      :lazy-src="image"
                      max-height="150"
                      max-width="150"
                      :src="image"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <div class="text-right">
                <v-btn
                  @click="_create"
                  :loading="loading"
                  class="btn btn-primary text-white"
                  >Send</v-btn
                >
                <v-btn
                    class="ml-3 text-white"
                    @click="back"
                    color="red"
                >Back</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <b-pagination
        v-if="discussion_threads.length > 0"
        class="pull-right mt-7"
        @input="getApplicationDiscussion"
        v-model="page"
        :total-rows="total"
        :per-page="perPage"
        first-number
        last-number
        :disabled="loading"
      >
      </b-pagination>

      <close-discussion ref="close-discussion"></close-discussion>
    </div>
  </v-app>
</template>
<script>
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";
import ApplicationDiscussionThreadService from "@/services/application/discussion/thread/ApplicationDiscussionThreadService";
import { required, requiredIf } from "vuelidate/lib/validators";
import CloseDiscussion from "@/view/pages/view/user/student/profile/components/application-discussion/CloseDiscussion";

const discussionThread = new ApplicationDiscussionThreadService();
const applicationDiscussion = new ApplicationDiscussionService();
export default {
  components: { CloseDiscussion },
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      loading: true,
      discussionId: "",
      discussion: {},
      images: [],
      discussion_threads: [],
      discussion_thread: {
        discussion_id: "",
        discussion_thread_id: "",
        description: "",
        attachmentType: "",
        files: [],
        attachment_url: "",
      },
      types: [
        { name: "File", value: "file" },
        { name: "Url", value: "url" },
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
    };
  },
  validations: {
    discussion_thread: {
      description: { required },

      files: {
        required: requiredIf(function () {
          return this.discussion_thread.attachmentType == "file";
        }),
      },
      attachment_url: {
        required: requiredIf(function () {
          return this.discussion_thread.attachmentType == "url";
        }),
      },
    },
  },
  mounted() {
    this.discussionId = this.$route.params.discussionId;
    this.getApplicationDiscussion();
    this.getApplicationDiscussionThreads();
  },
  computed: {
    formatted_date_time() {
      if (this.discussion && this.discussion.formatted_created_at) {
        let date = this.discussion.formatted_created_at.split(" ");
        return (
          date[0] +
          " " +
          date[1] +
          " " +
          date[2] +
          "," +
          date[3] +
          " " +
          date[4]
        );
      }
      return null;
    },
    no_of_files() {
      if (this.discussion_thread.files != null) {
        return this.discussion_thread.files.length;
      }
      return "0";
    },
  },
  methods: {
    back() {
        this.$tabs.close();
    },
    closeTicket() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$refs["close-discussion"].editApplicationDiscussion(
              this.discussion
            );
          }
        },
      });
    },
    previewImage() {
      this.images = [];
      let length =  this.discussion_thread.files.length;
      if(length > 0) {
        for (let i = 0; i < length; i++) {
          const fileType = this.discussion_thread.files[i].type;
          if(i <= 3 && fileType.includes('image')) {
            this.images.push(URL.createObjectURL(this.discussion_thread.files[i]));
          }
          if(fileType.includes('officedocument')) {
            this.images.push(require('@/assets/media/document.png'));
          }
          if(fileType.includes('application/pdf')) {
            this.images.push(require('@/assets/media/pdf.png'));
          }
        }
      }
    },
    convertToFormData() {
      let formData = new FormData();
      this.discussion_thread.discussion_id = this.discussionId;
      if (
        this.discussion_thread.files &&
        this.discussion_thread.files.length > 1
      ) {
        for (const file in this.discussion_thread.files) {
          if (
            this.discussion_thread.files[file] != null &&
            this.discussion_thread.files[file] !== undefined
          ) {
            formData.append(
              `files[${file}]`,
              this.discussion_thread.files[file]
            );
          }
        }
      }
      if (this.discussion_thread.files.length == 1) {
        formData.append("files", this.discussion_thread.files[0]);
      }
      for (let key in this.discussion_thread) {
        if (this.discussion_thread[key] && key != 'files') {
          formData.append(key, this.discussion_thread[key]);
        }
      }
      return formData;
    },
    _create: function () {
      let formData = this.convertToFormData();
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.loading = true;
        discussionThread
          .create(formData)
          .then((response) => {
            this.loading = false;
            this.$snotify.success(
              "Application Discussion Thread created successfully"
            );
            this.getApplicationDiscussionThreads();
            this.resetForm();
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
      }
    },
    getApplicationDiscussion() {
      applicationDiscussion
        .show(this.discussionId)
        .then((response) => {
          this.discussion = response.data.applicationDiscussion;
          if(this.discussion == null) {
            this.$router.push({
              name: '404'
            })
          }
        })
        .catch((err) => {});
    },
    getApplicationDiscussionThreads() {
      discussionThread
        .getPaginateByTicket(this.discussionId)
        .then((response) => {
          this.discussion_threads = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {})
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.$v.$reset();
      this.images = [];
      this.discussion_thread = {
        discussion_id: "",
        discussion_thread_id: "",
        description: "",
        attachmentType: "",
        files: [],
        attachment_url: "",
      };
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panels {
  z-index: unset !important;
}
.v-expansion-panel-header__icon {
  display: none !important;
}
</style>
